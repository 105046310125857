import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const Editorial: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="01-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{ backgroundImage: 'url(../../img/lon-edito-header.jpg)' }}
        ></div>
        <article className="post">
          <h1>Editorial</h1>
          <p>Dear colleagues,</p>
          <p>
            It is with great pleasure we are sending out our first e-commerce
            newsletter. We will keep you updated on a regular basis through this
            channel so that you are always aware on the e-commerce projects
            going on.
          </p>
          <p>Feel free to contact us for any remarks or suggestions.</p>
          <p>We wish you successful e-commerce sales !</p>
          <p>Kind regards,</p>
          <div className="team-card mt-3">
            <div className="d-flex flex-row align-items-center">
              <img
                src={'../../img/christophe-jeanneret.jpg'}
                className="team-portrait shadow-lg mr-3"
                style={{ width: '80px', height: '80px', borderRadius: '100%' }}
              />
              <div className="team-member">
                <h3>
                  <a
                    href="mailto:christophe.jeanneret@longines.com"
                    className="stretched-link"
                  >
                    Christophe Jeanneret
                  </a>
                </h3>
                <p>Head of International E-commerce</p>
              </div>
            </div>
          </div>
        </article>
      </div>
    </Layout>
  )
}

export default Editorial

export const pageQuery = graphql`
  query EditorialQuery_01_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
